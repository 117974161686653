<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" lg="12">
        <v-form>
          <v-card class="pa-4">
            <v-card-title>Schedule SMS </v-card-title>

            <v-divider class="mb-6 mx-4"></v-divider>
            <v-text-field
              label="Ttile"
              v-model="schedule.title"
              placeholder="Title"
              filled
              dense
            ></v-text-field>
            <v-autocomplete
              :items="rooms"
              item-text="name"
              item-value="id"
              v-model="schedule.sms_user"
              label="Class Room"
              multiple
            />

            <v-select
              :items="template_data"
              item-text="title"
              item-value="title"
              label="Choose Template"
            ></v-select>

            <!--   
              <v-text-field
              label="Phone number"
              v-model="phone"
              placeholder="Enter valid Phone number"
              filled
           
              dense
            ></v-text-field> -->
            <v-textarea
              :maxlength="this.max_count"
              label="Compose"
              :rules="rules"
              item-value="abbr"
              v-model="schedule.message"
              :value="value"
            ></v-textarea>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile>
                  Character count:{{ totalCount }} /{{ max_count }}
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile>
                  Remaining : {{ hasRemaining }}
                </v-card>
              </v-col>
              <v-col cols="6" md="4" lg="4">
                <v-card class="pa-2" outlined tile>
                  SMS count : {{ smsCount }}
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="schedule.schedule"
                label="Schedule"
              ></v-checkbox>
              <v-row>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="schedule.start_date"
                        chips
                        small-chips
                        label="Select Date(s)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        :rules="[
                          (v) => v.length > 0 || 'Select at least one date',
                        ]"
                        v-on="on"
                      ></v-combobox>
                    </template>

                    <v-date-picker
                      v-model="schedule.start_date"
                      :max="four_weeks_from_today"
                      :min="today"
                      no-title
                      scrollable
                      max-width="290px"
                      min-width="290px"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col align="right">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="schedule.start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="schedule.start_time"
                        label="Schedule Time"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="$requiredRules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      ampm-in-title
                      format="ampm"
                      color="red lighten-1"
                      v-model="schedule.start_time"
                      :max="end"
                      @click:minute="$refs.menu2.save(start_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="8" lg="7"> </v-col>
              <v-col cols="6" sm="6" md="1" lg="3"> </v-col>
              <v-col cols="6" md="1" lg="1">
                <v-btn
                  class="pa-2"
                  tile
                  v-if="max_count >= 100"
                  @click="sendSMS()"
                  color="primary"
                  block
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      dates: [],
      sms_send: false,

      template_data: [],
      template_name: [],
      rooms: [],
      start: null,
      end: null,
      // phone:'',
      menu: false,
      menu2: false,
      menu3: false,
      max_count: 100,

      // message: '',

      // title:"",
      id: "",
      name: "",
      selected_rooms: [],
      schedule: {},
      scheduleEdit: {},
    };
  },
  emits: ["closeDialog"],
  computed: {
    totalCount() {
      return this.item.message.length;
    },
    smsCount() {
      return parseInt(this.item.message.length / 10);
    },

    hasRemaining() {
      return this.max_count - this.totalCount;
    },
  },

  mounted() {
    this.loadTemplate();
    this.loadschedule();
    this.loadOwnedRooms();
  },
  methods: {
    loadTemplate() {
      return this.$api.get(`/template/`).then((response) => {
        this.template_data = response.data.results;
      });
    },
    loadschedule() {
      return this.$api.get(`/schedule/${this.item.id}/`).then((response) => {
        this.schedule = response.data;
      });
    },

    sendSMS() {
      const response = confirm(
        "Are you sure you want to remove from  Schedule?"
      );
      if (response) {
        if (this.schedule.schedule === false) {
          this.$api
            .put(`/schedule/${this.item.id}/`, this.schedule)
            .then((res) => {
              this.schedule = res.data;
            })
            .then(this.$emit("closeDialog"));
        }
      }
    },

    loadOwnedRooms() {
      this.$api.get("/rooms/").then((res) => {
        this.rooms = res.data.results;
      });
    },
  },
};
</script>

<style></style>
