<template>
  <v-container>
    <v-row justify="center">
      <v-toolbar min-width="100%" dark color="primary">
        <v-card-title>Schedule SMS </v-card-title>
      </v-toolbar>
      <v-col cols="12" md="8" lg="12" style="margin-top: 10px">
        <v-row style="margin-top: 10px; border: 1px solid grey">
          <v-col cols="3" class="text-right" style="margin-right: 20px">
            Filter by date
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="start_date"
              label="Start Date"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="end_date"
              label="End Date"
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" lg="12">
        <DataTableSSR
          apiEndPoint="/schedule/"
          :headers="headers"
          :instantLoad="true"
          :queryParams="{
            start_date: start_date,
            end_date: end_date,
          }"
        >
          <template #[`item.title`]="{ item }">
            <!-- <router-link :to="'/sms/' + item.id"> -->
            {{ item.title }}
            <!-- </router-link> -->
          </template>

          <template #[`item.created_at`]="{ item }">
            {{ new Date(item.created_at).toLocaleString() }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-dialog v-model="item.dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Show
                </v-btn>
                <!-- <v-switch
                  depressed
                  :input-value="item.is_active"
                  @change="toggleActive(item)"
                ></v-switch> -->
              </template>

              <schedule-compose
                :item="item"
                @closeDialog="item.dialog = false"
              ></schedule-compose>
            </v-dialog>
          </template>
        </DataTableSSR>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
import ScheduleCompose from "./ScheduleCompose.vue";
export default {
  props: ["item"],

  components: { DataTableSSR, ScheduleCompose },
  data() {
    return {
      start_date: null,
      end_date: null,
      headers: [
        {
          text: "Title",
          value: "title",
        },

        {
          text: "Created",
          value: "created_at",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
        },
      ],
      sendData: [],
      is_active: false,
      dialog: false,
    };
  },
  computed: {
    rowClasses() {
      return (item) => ({
        "active-row": item.is_active,
        "inactive-row": !item.is_active,
      });
    },
  },
  mounted() {
    this.loadSendSms();
    this.loadUnSendSms();
  },
  methods: {
    loadSendSms() {
      this.$api.get("send/send-number/").then((res) => {
        this.sendData = res.data;
      });
    },
    loadUnSendSms() {
      this.$api.get("unsend/unsend-number/").then((res) => {
        this.unsendData = res.data;
      });
    },
    toggleActive(item) {
      const newActiveState = !item.is_active;
      this.$api
        .put(`/mainsms/${item.id}/`, {
          schedule: newActiveState,
        })
        .then((response) => {
          item.is_active = response.data.schedule;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style></style>
